<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            ECA Entry
                            <v-spacer></v-spacer>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs3 sm4>
                                <v-select :loading="examLoading"
                                          :disabled="!exams.length"
                                          :items="exams" class="pa-0" label="Exam"
                                          v-model="exam" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm4>
                                <v-select :loading="gradeLoading" persistent-hint
                                          :items="grades" class="pa-0" label="Grade" v-model="grade"
                                          :disabled="!exam || !grades.length" outlined dense/>
                            </v-flex>
                            <v-flex xs3 sm4>
                                <v-select :items="sections" class="pa-0" label="Section" v-model="section"
                                          :disabled="!grade" outlined dense/>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <br>
                    <div class="overlay ecaOverlay">
                        <div class="data-represent" v-if="section">
                            <div>
                                <span class="d-success"></span> &nbsp; <strong>{{ecaAddedStudents}}</strong>
                                <small> ECA Added Students</small>
                            </div>
                            <div>
                                <span class="d-error"></span> &nbsp; <strong>{{ecaRemainingStudents}}</strong>
                                <small> ECA Remaining Students</small>
                            </div>
                            <div>
                                <span class="d-warning"></span> &nbsp; <strong>{{ecaAddedStudents +
                                ecaRemainingStudents}}</strong>
                                <small> Total Students</small>
                            </div>
                        </div>
                        <v-data-table
                                :headers="headers"
                                :hide-default-footer="form.items.data && form.items.data.length<1 || pagination.totalItems<pagination.rowsPerPage"
                                :items="form.items.data"
                                :loading="form.loading"
                                :options.sync="pagination"
                                footer-props.items-per-page-options="rowsPerPageItems"
                                class="marks_entry">
                            <template  v-slot:item="{index, item}">
                                <tr :class="''">
                                    <td class="text-xs-center">{{ item.roll }}</td>
                                    <td class="text-xs-left">{{ item.name }}</td>
                                    <td class="text-xs-center">
                                        <span v-if="item.achievement"> <v-chip color="warning" text-color="white"
                                                                                     small>Added</v-chip></span>
                                        <span v-else><v-chip color="error" text-color="white"
                                                             small>Pending</v-chip></span>
                                    </td>
                                    <td class="text-xs-center">
                                        <v-btn small icon color="warning" class="pa-0 ma-1"
                                               v-if="item.achievement"
                                               @click="editData(item.enroll_id,true)">
                                            <v-icon small>edit</v-icon>
                                        </v-btn>
                                        <v-btn small icon color="primary" class="pa-0 ma-1" v-else
                                               @click="editData(item.enroll_id,false)">
                                            <v-icon small>add</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>

                            </template>
                        </v-data-table>

                        <div v-if="section"
                             :class="'inner-overlay '+ (ecaLists && ecaLists.length <= 0 ?  '':'dont-show')">
                            <p style="margin-top: 20%;">
                                <v-icon style="font-size: 50px;color:red;">warning</v-icon>
                                <br>
                                ECA has not been set for this Class. <br>
                                <span style="font-size: 15px;"> Please set the ECA for this EXAM and CLASS first.</span>
                            </p>
                        </div>
                    </div>

                    <v-dialog v-model="form.dialog" persistent max-width="600px">
                        <v-card>
                            <v-card-text class="pb-1">
                                <v-data-table
                                        :headers="ecAheaders"
                                        :hide-default-footer="true"
                                        :items="ecaLists"
                                >
                                    <template  v-slot:item="{index, item}">
                                        <td>{{ props.index + 1 }}</td>
                                        <td class="text-xs-left">
                                            <strong>{{ item.name }}</strong>
                                        </td>
                                        <td class="text-xs-left">
                                            <div v-if="item.lists">
                                                <div class="eca" v-for="(list, i) in item.lists" :key="i">
                                                    <div class="eca-list1">
                                                        {{list.name}}
                                                    </div>
                                                    <div class="eca-list2">
                                                        <select class="ecaList" v-model="list.rating">
                                                            <option v-for="(a,b) in ecaList" :value="a.value"
                                                                    :key="b">{{a.text}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                    <template slot="footer">
                                        <tr>
                                            <td :colspan="3">
                                                <v-textarea :rows="3" v-model="form.comment"
                                                            label="Comment" outlined dense></v-textarea>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                                <v-btn color="success" outlined @click="save" :disabled="disabledBtn">Save</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    // import TemplateTest from "../../../../components/Generic/template-test";

    export default {
        // TemplateTest
        components: {},
        data: () => ({
            examLoading: false,
            gradeLoading: false,
            subjectLoading: false,
            rating: [],
            form: new Form({
                grade_id: '',
                enroll_id: '',
                exam_id: '',
                comment: '',
                eca_achievement: [],
            }, '/api/eca-achievement'),
            pagination: {
                rowsPerPage: 100,
            },
            rowsPerPageItems: [5, 10, 25, 50, 75, 100, 150],
            section: '',
            sections: [],
            exam: '',
            exams: [],
            grade: '',
            grades: [],
            headers: [
                {text: 'Roll', align: 'center', value: 'roll', width: 50},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'ECA', align: 'center', value: 'eca', sortable: false},
                {text: 'Action', align: 'center', value: 'action', sortable: false},
            ],
            ecAheaders: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'ECA', align: 'left', value: 'name', width: 150},
                {text: 'List', align: 'left', value: 'list'},
            ],
            ecaLists: [],
            ecaList: [
                // {text: 'A+', value: 'A+'},
                {text: 'A', value: 'A'},
                // {text: 'B+', value: 'B+'},
                {text: 'B', value: 'B'},
                // {text: 'C+', value: 'C+'},
                {text: 'C', value: 'C'},
                // {text: 'D+', value: 'D+'},
                {text: 'D', value: 'D'},
                // {text: 'E', value: 'E'}
            ],
            ecaAddedStudents: 0,
            ecaRemainingStudents: 0,

        }),
        computed: {
            ...mapState(['batch']),
            disabledBtn: function () {
                let val = false;
                this.ecaLists.map(res => {
                    res.lists.map(ecalist => {
                        if (ecalist.rating === "0") {
                            val = true
                        }
                    })
                });
                return val;
            }
        },
        mounted() {
            this.getExams();
        },
        watch: {
            'batch': function (value) {
                this.get();
            },
            'grade': function () {
                this.section = '';
                this.getSections();

            },
            'exam': function () {
                this.grade = '';
                this.form.items.data = [];
                this.getGrades();
            },
            'section': function () {
                this.form.items.data = [];
                this.get();
            },
            'pagination': function () {
                this.get()
            }
        },

        methods: {

            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&gradeId=' + this.grade.toString() + '&examId=' + this.exam.toString() + '&sectionId=' + this.section.toString();
            },

            get(params) {
                if (this.section) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    query = query.replace('&descending=false&page=1', '');
                    this.form.get(null, query).then(({data}) => {
                        this.pagination.totalItems = data.meta.total;
                        this.ecaAddedStudents = 0;
                        this.ecaRemainingStudents = 0;
                        data.data.map(eca => {
                            if (eca.achievement) this.ecaAddedStudents = this.ecaAddedStudents + 1;
                            else this.ecaRemainingStudents = this.ecaRemainingStudents + 1;
                        })
                        this.$rest.get('/api/eca-exam-list?gradeId=' + this.grade + '&examId=' + this.exam).then(res => {
                            this.ecaLists = res.data.data;
                        })
                    })
                }
            },

            save() {
                this.form.grade_id = this.grade;
                this.form.exam_id = this.exam;
                let ecaAchievement = [];
                this.ecaLists.map(res => {
                    res.lists.map(ecalist => {
                        ecaAchievement.push({
                            eca_exam_list_id: ecalist.eca_exam_list_id,
                            rating: ecalist.rating,
                        });
                    })
                });
                this.form.eca_achievement = ecaAchievement;
                this.form.store().then(res => {
                    this.get()
                });
            },
            getGrades() {
                this.gradeLoading = true;
                this.$rest.get('/api/exam-grade?rowsPerPage=25&descending=true&slim=true&examId=' + this.exam).then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.grade_id, text: item.name, evaluation: item.evaluation}
                    });
                }).finally(() => {
                    this.gradeLoading = false;
                })
            },
            getExams() {
                this.examLoading = true;
                this.$rest.get('/api/exam').then(({data}) => {
                    this.exams = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                }).finally(() => {
                    this.examLoading = false;
                })
            },
            getSections() {
                this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                    this.sections = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            editData(enrollId, dataFetch) {
                this.form.enroll_id = enrollId;
                if (dataFetch) {
                    this.$rest.get('/api/eca-achievement/detail?examId=' + this.exam + '&enrollId=' + enrollId).then(response => {
                        this.form.comment = response.data.data.comments;
                        this.ecaLists.map(res => {
                            res.lists.map(ecalist => {
                                response.data.data.achievement.map(eca => {
                                    if (eca.eca_exam_list_id === ecalist.eca_exam_list_id) {
                                        ecalist.rating = eca.rating;
                                    }
                                })

                            })
                        });
                    });
                } else {
                    this.ecaLists.map(res => {
                            res.lists.map(ecalist => {
                                ecalist.rating = 0;
                            })
                        }
                    );
                }
                this.form.dialog = true;
            },
            getAchievement() {

            }
        }
    }
</script>
<style lang="scss">
    .attendance {
        .inner-overlay p {
            margin-top: 3%;
        }
    }

    .eca {
        display: flex;
    }

    .eca-list1 {
        flex: 2;
    }

    .eca-list2 {
        flex: 1;
    }

    .ecaList {
        width: 37px;
        border: solid 1px #777;
        padding-left: 10px;
        margin: 1px;
        float: right !important;
    }

    select.ecaList:disabled {
        background: #eee;
        cursor: not-allowed;
    }
</style>